<template>

  <nav class="navbar navbar-expand-md">

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="du-navbar">
        <ul class="nav navbar-nav">

          <li class="nav-item">
            <router-link v-bind:class = "(activePage === 'Welcome')?'nav-link active':'nav-link'" tag="a" to="/">WELKOM</router-link>
          </li>

          <li class="nav-item">
            <router-link v-bind:class = "(activePage === 'HowItWorks')?'nav-link active':'nav-link'" tag="a" to="/hoe-werkt-het">HOE WERKT HET</router-link>
          </li>

          <li class="nav-item">
            <router-link v-bind:class = "(activePage === 'StartRequest' || activePage === 'New-Request')?'nav-link active':'nav-link'" tag="a" to="/start-aanvraag">START UW AANVRAAG</router-link>
          </li>

          <li class="nav-item mb-2 mb-md-0">
            <router-link v-bind:class = "(activePage === 'QuestionList')?'nav-link active':'nav-link'" tag="a" to="/vragenlijst">TOETS</router-link>
          </li>

          <li class="nav-item">
            <Auto0></Auto0>
          </li>

<!--          <p v-if="activePage === 'QuestionList'" class="nav-item du-navbar-usertext"><span>|</span> WELKOM 'NAAM' <i class="fa-solid fa-chevron-down du-navbar-usertext-icon"></i></p>-->
          <p v-if="isLoggedIn && user !== null" class="nav-item du-navbar-usertext"><span>|</span> Welkom {{ user.firstName }} <i class="fa-solid fa-chevron-down du-navbar-usertext-icon"></i></p>

      </ul>
      </div>
    </div>
  </nav>

</template>


<script>
import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'
import Auto0 from "@/components/auth0.vue";

let userStore
let userVar
let loggedIn
export default {
  name: 'Navigation',
  components: {Auto0},

  setup() {
    userStore = useUserStore()
    const {user, isLoggedIn} = storeToRefs(userStore)
    userVar = user.value
    loggedIn = isLoggedIn.value
    return {user, isLoggedIn}
  },

  data() {
    return {
      fillCount: 0,
    };
  },

  props: {
    activePage: {
      type: String
    }
  },

  methods: {

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    logOut() {
      userStore.user = null
      userStore.redirectFail = false
      userStore.falseLogin = 0
      this.sleep(1000);

      this.$router.push('/login')
      console.log("isLoggedIn === false")
      userStore.isLoggedIn = false

      this.$router.push('/uitloggen')
    }

  },

  watch: {
    user: {
      // This will let Vue know to look inside the array
      deep: true,
      handler(value, oldValue) {
        if (oldValue === null) {
          console.log(userStore.user);
        } else {
          console.log(value, oldValue);
        }
      }
    },
  },

  // beforeMount() {
  //   if (userStore.user === null) {
  //     console.log("user is false")
  //   }
  // }

}



</script>
