<template>
  <footer>
      <hr>
      <div class="footerContentContainer">
        <p id="copy"><span> &copy; {{ new Date().getFullYear() }} Dividenduitkering.nl</span> is onderdeel van <a class="drv-footer-link-logo" href="https://www.moore-drv.nl"><img id="footerLogo" class="footerCompLogo" src="@/assets/Footer_Logo_wit.png" alt="Footer logo kleur" srcset=""></a> <br v-if="windowCheckerData.isMobileScreenXS"></p>
        <p class="urls" ><a class="drv-footer-link" href="https://www.moore-drv.nl/algemene-voorwaarden/">Algemene voorwaarden</a><a class="drv-footer-link" href="https://www.moore-drv.nl/privacyverklaring/">Privacyverklaring</a><a class="drv-footer-link" href="https://www.moore-drv.nl/cookieverklaring/">Cookieverklaring</a><a class="drv-footer-link" href="https://www.moore-drv.nl/disclaimer/">Disclaimer</a></p>
      </div>
      
    </footer>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>
</template>


<script>


import WindowChecker from "@/components/WindowChecker.vue";

export default {
  components: {WindowChecker},
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
      hello: 'footer',
    }
  },
}



</script>
