<script>
import { useAuth0 } from '@auth0/auth0-vue';

export default{
  setup() {
    const auth0 = useAuth0();

    return {
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      user: auth0.user,
      login() {
        auth0.loginWithRedirect();
      },
      logout() {
        auth0.logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      },
      showUser(){
        console.log(this.user)
      },
      capitalize(text) {
      if (!text) return '';
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
    }
  
  },
}
</script>

<template>
    <span v-if="!isAuthenticated && !isLoading"  class="nav-link" id="qsLoginBtn" @click.prevent="login">INLOGGEN</span>
    <div class="nav-item dropdown " v-if="isAuthenticated">
      <a
          class="nav-link dropdown-toggle text-uppercase"
          href="#"
          id="profileDropDown"
          data-toggle="dropdown"
      >
        {{ user.given_name ? user.given_name : user.nickname }}
        <!-- <button v-on:click="showUser()" >showusr</button> -->
        <img  v-if="user.picture" class="userPicture"  :src="user.picture">
        <img  v-else class="userPicture"  :src="'@assets/profile-silhouette.webp'" >
      </a>
      <div class="dropdown-menu dropdown-menu-right navDropdown">
        <div class="dropdown-header">
        </div>
        <div class="userInfo">
          <div class="top" >
            <b>{{ user.given_name ? user.given_name.toUpperCase() : user.nickname.toUpperCase() }}</b> 
            <img class="userPicture" :src="user.picture" >
          </div>

          <div class="body">
            <b>
              {{ user.given_name ? capitalize(user.given_name) : capitalize(user.nickname) }}{{ user.given_name || user.nickname ? ' ' : '' }}{{ user.family_name || '' }}
            </b>
            <br/>
            {{  (user.given_name && user.family_name) ? `${user.given_name.replace(" ","")}.${user.family_name.replace(" ","")}@mooredrv.com` : user.email }}
          </div>
        </div>
        
        <router-link to="/profile" class="dropdown-item dropdown-profile" @click="showUser">
          <font-awesome-icon class="mr-3" icon="user" />Profiel
        </router-link>
        <!-- <router-link to="/overzicht" class="dropdown-item dropdown-profile" @click="showUser">
          <font-awesome-icon class="mr-3" icon="user" />Overzicht
        </router-link> -->
        <a id="qsLogoutBtn" href="#" class="dropdown-item" @click.prevent="logout">
          <font-awesome-icon class="mr-3" icon="power-off" />Uitloggen
        </a>
      </div>
    </div>

  <ul class="navbar-nav d-md-none" v-if="!isAuthenticated && !isLoading">
    <button id="qsLoginBtn" class="btn btn-primary btn-block" @click="login">Log in</button>
  </ul>

  <ul
      id="mobileAuthNavBar"
      class="navbar-nav d-md-none d-flex"
      v-if="isAuthenticated"
  >
    <li class="nav-item">
              <span class="user-info">
                <img
                    :src="user.picture"
                    alt="User's profile picture"
                    class="nav-user-profile d-inline-block rounded-circle mr-3"
                    width="50"
                />
                <h6 class="d-inline-block">{{ user.name }}</h6>
              </span>
    </li>
    <li>
      <font-awesome-icon icon="user" class="mr-3" />
      <router-link to="/profile">Profile</router-link>
    </li>

    <li>
      <font-awesome-icon icon="power-off" class="mr-3" />
      <a id="qsLogoutBtn" href="#" class @click.prevent="logout">Log out</a>
    </li>
  </ul>
</template>

<style scoped>
</style>
