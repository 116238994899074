<template>
  <title>Inloggen | Dividenduitkering</title>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12 d-md-none px-0 cpyl">
        <div class="du-lottie-player">
          <lottie-player v-pre autoplay loop mode="normal" src="drv.json" style="width: 100%;display: inline-block"></lottie-player>
        </div>
      </div>
      <div class="col-md-6 col-12 cpbm du-column-bg-white min-height" :class="{'cptl cpxxl': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen}">

        <div class="du-title-text du-login-title d-flex align-items-center">
          <h1>Inloggen</h1>
        </div>

        <div class="cmtm">
          <form @submit.prevent="login">

            <!--          <p> {{ filledIn }}</p>-->
            <!--          <p> {{ fillCount }}</p>-->
            <!--          <p> {{ email }}</p>-->
            <!--          <p> {{ password }}</p>-->
            <!--          <p> {{user}} </p>-->
            <!--          <p> {{ isLoggedIn }}</p>-->
            <!--          <p> {{ falseLogin }}</p>-->

            <div class="row du-login-content">
              <div class="col-12">
                <div class="row">
                  <p v-if="redirectFail === true" class="du-error-text">U zult eerst moeten inloggen alvorens verder te gaan.</p>
                  <div class="col-12">
                    <p class="du-r-input-annotation du-login-input-annotation">Email*</p>
                    <div class="du-question-input du-login-input mt-4">
                      <input type="text" v-model="email" class="du-input du-r-input">
                    </div>
                  </div>

                  <div class="col-12 mt-4">
                    <p class="du-r-input-annotation du-login-input-annotation">Wachtwoord*</p>
                    <div class="du-question-input du-login-input mt-4">
                      <input type="password" v-model="password" class="du-input du-r-input">
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="du-login-form-bottom w-100">
                      <div class="du-login-form-bottom justify-content-between mt-3">
                        <div class="row">
                          <div class="col-lg col-12">
                            <div class="du-checkbox-group">
                              <label class="du-radio du-radio-label du-checkbox du-checkbox-login du-label mt-0">
                                <input type="checkbox" class="du-radio-btn" name="r" value="value" checked>
                                <span>Remember me</span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg col-12 text-start" :class="{'mt-3': windowCheckerData.isMobileScreen || windowCheckerData.isTabletScreen, 'text-end': !windowCheckerData.isMobileScreen && !windowCheckerData.isTabletScreen}">
                            <router-link to="/wachtwoord/vergeten">
                              <p class="du-r-input-annotation du-login-input-annotation mt-0">Forgot password?</p>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-5">
                    <button type="submit" v-bind:class="filledIn === false ? 'du-button du-button-dark disabled-button':'du-button du-button-dark'">INLOGGEN
                      <i class="fa-solid fa-chevron-right thick-icon"></i>
                    </button>
                  </div>

                  <div class="col-12">
                    <div class="du-login-registerlink">
                      <p>Nog geen account? <router-link tag="span" to="/registreren" class="ms-1">Registeren</router-link></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-6 d-md-flex d-none du-lottie-column d-flex align-items-center" :class="{'cptl justify-content-end pe-0': !windowCheckerData.isMobileScreen, 'p-0': windowCheckerData.isMobileScreen}">
        <div class="du-lottie-player h-100">
          <lottie-player v-pre loop autoplay mode="normal" src="drv.json" style="width: 100%;display: inline-block"></lottie-player>
        </div>
      </div>
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>

</template>

<script>

import {useUserStore} from "@/store/user";
import {storeToRefs} from "pinia";
import WindowChecker from "@/components/WindowChecker.vue";
let userStore
let userVar
let loggedInVar
let redFail
let falseLoginCount
export default {
  name: 'Navigation',
  components: {WindowChecker},

  setup() {
    userStore = useUserStore()
    const {user, isLoggedIn, redirectFail, falseLogin } = storeToRefs(userStore)
    userVar = user.value
    loggedInVar = isLoggedIn.value
    redFail = redirectFail.value
    falseLoginCount = falseLogin.value
    return {user, isLoggedIn, redirectFail, falseLogin}
  },

  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
      email: "",
      password: "",
      filledIn: false,
      fillCount: 0,
    };
  },

  watch: {

    isLoggedIn: {
      deep: true,
      handler() {
        this.sleep(1000);
        this.$router.push("/overzicht");
      }
    },

    email: function (value) {
      if (value.length > 0) {
        this.fillCount = 1
      } else {
        this.fillCount = 0
      }
    },

    password: function (value) {
      if (value.length > 0 && this.email.length > 0) {
        this.fillCount = 2
      } else {
        this.fillCount = 0
      }
    },

    fillCount: function (value) {
      if (value === 2) {
        this.filledIn = true
      } else if (value !== 2) {
        this.filledIn = false
      }
    },

  },

  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    test() {
      userStore.user = Math.random() * 100;
    },

    async login() {
      const loginResult = await userStore.signIn(this.email, this.password)
      console.log(loginResult)

    }

  }
}
</script>
<!--<script setup>-->
<!--import { useUserStore } from "@/store/user";-->
<!--import { watch } from 'vue'-->
<!--import {storeToRefs} from "pinia";-->

<!--const userStore = useUserStore()-->
<!--const { check, user  } = storeToRefs(userStore)-->

<!--watch(check, () => {-->
<!--  console.log('check ref changed, do something!')-->
<!--})-->

<!--watch(user, () => {-->
<!--  console.log('some user  changed', user)-->
<!--})-->

<!--</script>-->

<!--<script>-->
<!--import { useUserStore } from "@/store/user";-->
<!--import {storeToRefs} from "pinia";-->

<!--let UStore-->
<!--let userState-->
<!--export default {-->


<!--  // setup() {-->
<!--  //   UStore = useUserStore();-->
<!--  //   userState = storeToRefs(UStore)-->
<!--  // },-->

<!--  setup() {-->
<!--    UStore = useUserStore()-->
<!--    userState = storeToRefs(UStore)-->
<!--    return { UStore }-->
<!--  },-->

<!--  data() {-->
<!--    return {-->
<!--      email: "",-->
<!--      password: "",-->
<!--      filledIn: false,-->
<!--      fillCount: 0,-->
<!--    };-->
<!--  },-->

<!--  watch: {-->

<!--    email: function (value) {-->
<!--      if (value.length > 0) {-->
<!--        this.fillCount = 1-->
<!--      } else {-->
<!--        this.fillCount = 0-->
<!--      }-->
<!--    },-->

<!--    password: function (value) {-->
<!--      if (value.length > 0 && this.email.length > 0) {-->
<!--        this.fillCount = 2-->
<!--      } else {-->
<!--        this.fillCount = 0-->
<!--      }-->
<!--    },-->

<!--    fillCount: function (value) {-->
<!--      if (value === 2) {-->
<!--        this.filledIn = true-->
<!--      } else if (value !== 2) {-->
<!--        this.filledIn = false-->
<!--      }-->
<!--    },-->
<!--  },-->

<!--  methods: {-->
<!--    useUserStore,-->

<!--    checkEmail() {-->
<!--      if (this.email !== '' || this.email !== null || !(this.email.length > 0)) {-->
<!--        this.fillCount++-->
<!--      } else if (this.email.length === 0) {-->
<!--        console.log("leeg")-->
<!--        this.fillCount&#45;&#45;-->
<!--      }-->
<!--    },-->

<!--    test() {-->
<!--      UStore.user = "test"-->
<!--      console.log(UStore.user)-->
<!--    },-->

<!--    async login() {-->
<!--      console.log("test")-->
<!--      await UStore.signIn(this.email, this.password)-->
<!--    }-->
<!--  },-->

<!--  mounted() {-->
<!--  console.log(UStore.user);-->
<!--  }-->

<!--}-->
<!--</script>-->


<style scoped>

</style>
