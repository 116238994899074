<template>
  <title>Aanvraag | Dividenduitkering</title>

  <div class="container-fluid" :class="{'cpbm': windowCheckerData.isMobileScreen}">
    <div class="row request-row">
      <div class="d-md-none d-block col-12 du-column-bg-white" :class="{'cptl cpxxl': !windowCheckerData.isMobileScreen, 'cpm' : windowCheckerData.isMobileScreen}">
        <div class="row">
          <div class="col-12 du-data-overview">
            <div class="du-content-title du-data-title mt-0 mb-4 text-center w-100">
              <h2>WINKELMAND</h2>
            </div>
            <div class="du-data-overview-datatitle">
              <h3>NAAM</h3>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <div class="du-data-overview-seperator-thick"></div>
            </div>
            <div v-for="shop in shoppingcart" class="col-12">
              <div class="du-data-overview-data">
                <div class="row">
                  <div class="col-9">
                    <div class="du-data-overview-text">
                      <h4>{{ shop.name }}</h4>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="du-data-overview-text du-data-overview-text-last d-flex justify-content-end">
                      <!--                      <h4>€{{ shop.price }}</h4>-->
                      <h4>€9999,99</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="du-data-overview-seperator"></div>
            </div>

            <div class="col-12">
              <div class="du-data-overview-data mt-4">
                <div class="du-data-overview-text">
                  <div class="row">
                    <div class="col-6">
                      <div class="du-data-overview-bottomtext">
                        <h4>TOTAAL</h4>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="du-data-overview-bottomtext-last d-flex justify-content-end">
                        <h4>€199,99</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="du-data-overview d-flex align-items-center">-->
            <!--              <div class="row w-100">-->
            <!--                <div class="col-12 d-flex justify-content-center">-->
            <!--                  -->
            <!--                </div>-->

            <!--                <div class="col-3 mb-1">-->
            <!--                  -->
            <!--                </div>-->


            <!--                -->

            <!--                -->

            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12" :class="{'cptl cpxxl cpbm min-height': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen}">
        <div class="du-title-block d-inline-block mb-md-3">
          <div class="du-title-text h-100 d-flex align-items-center">
            <h1>NIEUWE AANVRAAG</h1>
          </div>
        </div>

        <div class="du-content-title">
          <h2>Dit is het overzicht van al uw aanvragen. Wilt u een nieuwe aanvraag doen?</h2>
        </div>

        <div class="row mt-3">
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-12 col-xl-6">
                  <p class="du-r-input-annotation">Voornaam*</p>
                  <div class="du-question-input mt-4">
                    <input type="text" class="du-input du-r-input">
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-12 col-xl-6">
                  <p class="du-r-input-annotation">Achternaam*</p>
                  <div class="du-question-input mt-4">
                    <input type="text" class="du-input du-r-input">
                  </div>
                </div>

                <div class="col-12">
                  <p class="du-r-input-annotation">Email*</p>
                  <div class="du-question-input mt-4">
                    <input type="text" class="du-input du-r-input">
                  </div>
                </div>

                <div class="col-12">
                  <p class="du-r-input-annotation">Bedrijfsnaam</p>
                  <div class="du-question-input mt-4">
                    <input type="text" class="du-input du-r-input">
                  </div>
                </div>

                <div class="col-12">
                  <p class="du-r-input-annotation">BTW-nummer</p>
                  <div class="du-question-input mt-4">
                    <input type="text" class="du-input du-r-input">
                  </div>
                </div>

                <div class="du-request-form-seperator"></div>

                <div class="du-content-subtitle du-request-subtitle mt-0">
                  <h3>Factuuradres</h3>
                </div>

                <div class="col-12">
                  <p class="du-r-input-annotation">Straat & huisnummer</p>
                  <div class="du-question-input mt-4">
                    <input type="text" class="du-input du-r-input">
                  </div>
                </div>

                <div class="col-12">
                  <p class="du-r-input-annotation">Postcode</p>
                  <div class="du-question-input mt-4">
                    <input type="text" class="du-input du-r-input du-input-zipcode" maxlength="7">
                  </div>
                </div>

                <div class="du-request-form-seperator"></div>

                <div class="du-content-subtitle du-request-subtitle mt-0">
                  <h3>Betalingsmethode</h3>
                </div>

                <div class="du-request-payment">
                  <div class="row" :class="{'row-cols-auto': windowCheckerData.isMobileScreen}">

                    <div :class="{
                      'cpbs': windowCheckerData.isMobileScreen || windowCheckerData.isTabletScreen,
                      'col': windowCheckerData.isMobileScreenS,
                      'col-4': windowCheckerData.isMobileScreenXS || windowCheckerData.isTabletScreen,
                      'd-inline-block w-auto':!windowCheckerData.isMobileScreen && !windowCheckerData.isTabletScreen}">
                      <img class="du-payment-img" src="../assets/DRV_betaalmethode_ideal.png" alt="">
                    </div>
                    <div :class="{
                      'cpbs': windowCheckerData.isMobileScreen || windowCheckerData.isTabletScreen,
                      'col': windowCheckerData.isMobileScreen,
                      'col-4': windowCheckerData.isMobileScreenXS || windowCheckerData.isTabletScreen,
                      'd-inline-block w-auto':!windowCheckerData.isMobileScreen && !windowCheckerData.isTabletScreen}">
                      <img class="du-payment-img" src="../assets/DRV_betaalmethode_mastercard.png" alt="">
                    </div>
                    <div :class="{
                      'cpbs': windowCheckerData.isMobileScreen || windowCheckerData.isTabletScreen,
                      'col': windowCheckerData.isMobileScreenS,
                      'col-4': windowCheckerData.isMobileScreenXS || windowCheckerData.isTabletScreen,
                      'd-inline-block w-auto':!windowCheckerData.isMobileScreen && !windowCheckerData.isTabletScreen}">
                      <img class="du-payment-img" src="../assets/DRV_betaalmethode_paypal.png" alt="">
                    </div>

                  </div>

                  <div class="du-question-controls du-controls-content-smallmargin d-flex justify-content-between mb-2">

                      <router-link to="/start-aanvraag">
                        <div class="du-question-control-left d-inline-block">
                          <button class="du-button du-button-back">
                            <i class="fa-solid fa-chevron-left thick-icon"></i>
                            TERUG
                          </button>
                        </div>
                      </router-link>

                      <a :href="mollieLink">
                        <div class="du-question-control-right d-inline-block">
                          <button class="du-button">
                            BETALEN
                            <i class="fa-solid fa-chevron-right thick-icon"></i>
                          </button>
                        </div>
                      </a>
                  </div>

                </div>
              </div>
            </div>
          </div>

      </div>

      <div class="d-md-block d-none col-md-6 col-12 du-column-bg-white" :class="{'cptl cpxxl min-height': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen}">
        <div class="row">
          <div class="col-12 du-data-overview position-relative">
            <div :class="{'du-data-overview cpxxl': !windowCheckerData.isMobileScreen}">
              <div class="du-content-title du-data-title mt-0 mb-4 text-center w-100">
                <h2>WINKELMAND</h2>
              </div>
              <div class="du-data-overview-datatitle">
                <h3>NAAM</h3>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <div class="du-data-overview-seperator-thick"></div>
              </div>
              <div v-for="shop in shoppingcart" class="col-12">
                <div class="du-data-overview-data">
                  <div class="row">
                    <div class="col-9">
                      <div class="du-data-overview-text">
                        <h4>{{ shop.name }}</h4>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="du-data-overview-text du-data-overview-text-last d-flex justify-content-end">
                        <!--                      <h4>€{{ shop.price }}</h4>-->
                        <h4>€9999,99</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="du-data-overview-seperator"></div>
              </div>
              <div class="col-12">
                <div class="du-data-overview-data mt-4">
                  <div class="du-data-overview-text">
                    <div class="row">
                      <div class="col-6">
                        <div class="du-data-overview-bottomtext">
                          <h4>TOTAAL</h4>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="du-data-overview-bottomtext-last d-flex justify-content-end">
                          <h4>€199,99</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>
</template>

<script>
import WindowChecker from "@/components/WindowChecker.vue";
export default {
  name: "RequestForm.vue",
  components: {WindowChecker},
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
    // async createPayment() {
    //   const payment = await mollieClient.payments.create({
    //     amount: {
    //       currency: 'EUR',
    //       value: '10.00',
    //     },
    //     description: 'Payment for your order',
    //     redirectUrl: '/betaling/succes', // Redirect URL after payment
    //   });
    //
    //   // Redirect the user to the payment page
    //   window.location.href = payment._links.checkout.href;
    // }
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
      options: [
        {id: '1', name: 'Geen rekening houden met vennootschapsbelasting'},
        {id: '2', name: 'Rekening houden met vennootschapsbelasting ad 20%'},
        {id: '3', name: 'Rekening houden met vennootschapsbelasting ad 25%'},
      ],
      shoppingcart: [
        {id: '1', name: 'Aanvraag Dividenduitkering', price: '99,99'},
        {id: '2', name: 'BTW (21%)', price: '20,79'},
      ],
      mollieLink: "https://drv.imaginecreativeagency.nl/mollie/index.php",
    }
  },
}
</script>

<style scoped>

</style>
