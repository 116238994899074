<template>
  <title>Betaling | Dividenduitkering</title>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12 du-column-white d-flex flex-column justify-content-center" :class="{
        'min-height cpxxl': !windowCheckerData.isMobileScreen,
        'cpxm': windowCheckerData.isMobileScreen,
        'min-height-mobile-s': windowCheckerData.isMobileScreenS,
        'min-height-mobile-xs': windowCheckerData.isMobileScreenXS
      }">
        <div class="du-title-text du-between-title mt-0 d-flex justify-content-center text-center">
          <h1>Betaling {{orderStatus?.status_name}}</h1>
        </div>
        <div class="du-between-subtext-v2  d-flex justify-content-center">
          <p>{{orderStatus.paragraph}}</p>

        </div>
        <div class="du-between-button mt-0 d-flex justify-content-center">
          <a v-for="(btn, index) in orderStatus.btns" :href="btn.btn_href">
            <div class="du-question-control-right d-inline-block">
              <button :class="btn.btn_classList + ' du-button'">
                {{btn.btn_name}}
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>
</template>

<script>
import WindowChecker from "@/components/WindowChecker.vue";

export default {
  name: "Payment",
  components: {WindowChecker},
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
    orderStatusChecker(status){
      console.log(status)
      switch(status){
        case "succes":
          this.orderStatus.status_name = "succes"
          this.orderStatus.paragraph = "Uw betaling was succesvol. U kunt van start gaan met uw aanvraag. Uw aanvraag blijft 24 uur opgeslagen, zodat u het zo goed mogelijk in kan vullen."
          this.orderStatus.btns =
          [
            {
              btn_classList: "du-button-dark",
              btn_name:"GA VERDER",
              btn_href: "https://drv.imaginecreativeagency.nl/vragenlijst"
            }
          ]
          break;
        case "verlopen":
          this.orderStatus.status_name = "verlopen"
          this.orderStatus.paragraph = "Uw betaling was verlopen. Probeer het opnieuw."
          this.orderStatus.btns =
              [
                {
                  btn_classList: "du-button-dark",
                  btn_name:"OPNIEUW BETALEN",
                  btn_href: "https://drv.imaginecreativeagency.nl/mollie/index.php"
                }
              ]
          break;
        case "annuleert":
          this.orderStatus.status_name = "annuleert"
          this.orderStatus.paragraph = "Uw betaling was annuleert. Probeer het opnieuw of ga terug naar het overzicht."
          this.orderStatus.btns =
              [{
                btn_classList: "du-button-dark",
                btn_name:"OPNIEUW BETALEN",
                btn_href: "https://drv.imaginecreativeagency.nl/mollie/index.php"
              },{
                btn_classList: "",
                btn_name:"TERUG OVERZICHT",
                btn_href: "https://drv.imaginecreativeagency.nl/#/overzicht"
              }]
          break;
        case "mislukt":
          this.orderStatus.status_name = "mislukt"
          this.orderStatus.paragraph = "Uw betaling was verlopen. Probeer het opnieuw."
          this.orderStatus.btns =
              [
                {
                  btn_classList: "du-button-dark",
                  btn_name:"OPNIEUW BETALEN",
                  btn_href: "https://drv.imaginecreativeagency.nl/mollie/index.php"
                }
              ]
          break;
      }
    }
  },
  created() {
    this.orderStatus = {
      status_name: "",
      paragraph: "",
      btns:[]
    }
    const payStatus = this.$route.params.status
    this.orderStatusChecker(payStatus)
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
      mollieLink: "https://drv.imaginecreativeagency.nl/mollie/index.php",
      orderStatus:{
        status_name: "",
        paragraph: "",
        btns:[]
      },
    }
  }}
</script>

<style scoped>

</style>
