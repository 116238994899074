<template>
  <title>Welkom | Dividenduitkering</title>

  <div class="container-fluid" :class="{'cpbm': windowCheckerData.isMobileScreen}">
    <div class="row">
      <div class="col-12 d-md-none px-0 cpyl">
          <div class="du-lottie-player">
            <lottie-player v-pre autoplay loop mode="normal" src="drv.json" style="width: 100%;display: inline-block"></lottie-player>
          </div>
      </div>
      <div class="col-md-6 col-12"
           :class="{'cptl cpxxl': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen}"
      >

        <!-- <div class="du-title-block d-inline-block mb-md-3">
          <div class="du-title-text h-100 d-flex align-items-center">
            <h1>WELKOM</h1>
          </div>
        </div> -->

        <div class="cmtm">
          <div class="cmbm">
            <h2 class="largeTextXl">Heeft u recht tot het uitkeren van dividend?</h2>
          </div>

          <div class="cmbm">
            <!-- <h3>Neem de tijd</h3> -->
            <p>Doormiddel van een serie vragen achterhalen wij de mogelijkheid en omvang van uw recht tot het uitkeren van dividend uit uw onderneming.</p>
          </div>


          <!-- <div class="cmbm">
            <h3>Lorem ipsum</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div> -->

          <div class="du-controls-content d-flex">
            <div class="w-100">

              <div class="du-question-control-left d-md-inline-block d-block cmbs" :class="{'w-100': windowCheckerData.isTabletScreen}">
                <div class="cmbm">
                  <p>Gemiddeld kost het invullen u <span>ruim 30 minuten</span></p>
                </div>
                <router-link to="/registreren" class="d-block">
                  <button class="du-button">BEGIN UW AANVRAAG
                    <i class="fa-solid fa-chevron-right thick-icon"></i>
                  </button>
                </router-link>
              </div>

              <!-- <div class="du-question-control-right d-md-inline-block d-block" :class="{'w-100': windowCheckerData.isTabletScreen}"> -->
<!--                <router-link to="/login" class=" d-block">-->
                  <!-- <button @click="login" class="du-button du-button-transparent">INLOGGEN
                    <i class="fa-solid fa-chevron-right thick-icon"></i>
                  </button> -->
<!--                </router-link>-->
              <!-- </div> -->

            </div>
          </div>

        </div>

      </div>

      <div class="col-md-6 d-md-flex d-none du-lottie-column d-flex align-items-center" :class="{'cptl justify-content-end pe-0': !windowCheckerData.isMobileScreen, 'p-0': windowCheckerData.isMobileScreen}">
        <div class="du-lottie-player h-100">
          <!-- <lottie-player v-pre loop autoplay mode="normal" src="drv.json" style="width: 100%;display: inline-block"></lottie-player> -->
          <!-- <img src="@/assets/Isometric02.PNG" alt="Isometric"> -->
          <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">

        </div>
      </div>

    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>

</template>

<script>

import { useAuth0 } from '@auth0/auth0-vue';
import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import WindowChecker from "@/components/WindowChecker.vue";


export default {
  name: `Welcome`,
  components: {WindowChecker, Navigation, Header, Footer, 'lottie': LottiePlayer},
  setup() {
    const auth0 = useAuth0();
    console.log(auth0)
    return {
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      user: auth0.user,
      login() {
        auth0.loginWithRedirect();
      },
      logout() {
        auth0.logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      }
    }
  },
  methods: {
    windowChecker(data) {
      console.log('Hello checker')
      this.windowCheckerData = data;
    },
  },

  mounted() {
    const encodedString = "%7B%22alt%22:%22%22,%22title%22:%22Oesterdam_header_oesterdammijnevents%22,%22caption%22:%22%22,%22description%22:%22%22,%22id%22:4041,%22link%22:%22https://oesterdamwp.imaginecreativeagency.nl/events/mijn-oesterdam-events/oesterdam_header_oesterdammijnevents/%22,%22url%22:%22https://oesterdamwp.imaginecreativeagency.nl/wp-content/uploads/2023/08/Oesterdam_header_oesterdammijnevents.jpg%22,%22sizes%22:%7B%22thumbnail%22:%7B%22height%22:150,%22width%22:150,%22url%22:%22https://oesterdamwp.imaginecreativeagency.nl/wp-content/uploads/2023/08/Oesterdam_header_oesterdammijnevents-150x150.jpg%22,%22orientation%22:%22landscape%22%7D,%22medium%22:%7B%22height%22:169,%22width%22:300,%22url%22:%22https://oesterdamwp.imaginecreativeagency.nl/wp-content/uploads/2023/08/Oesterdam_header_oesterdammijnevents-300x169.jpg%22,%22orientation%22:%22landscape%22%7D,%22large%22:%7B%22height%22:422,%22width%22:750,%22url%22:%22https://oesterdamwp.imaginecreativeagency.nl/wp-content/uploads/2023/08/Oesterdam_header_oesterdammijnevents-1024x576.jpg%22,%22orientation%22:%22landscape%22%7D,%22full%22:%7B%22url%22:%22https://oesterdamwp.imaginecreativeagency.nl/wp-content/uploads/2023/08/Oesterdam_header_oesterdammijnevents.jpg%22,%22height%22:1080,%22width%22:1920,%22orientation%22:%22landscape%22%7D%7D%7D";

// URL-decode the string
    const decodedString = decodeURIComponent(encodedString);

// Parse the JSON data into an object
    const dataObject = JSON.parse(decodedString);

    console.log(dataObject);
  },

  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },

      // Initialize a data property to store the received data
      options: {
        minimizable: false,
        playerSize: "standard",
        backgroundColor: '#fff',
        backgroundStyle: 'color',
        theme: {
          controlsView: "standard",
          active: "light",
          light: {
            color: '#3D4852',
            backgroundColor: '#fff',
            opacity: '0.7',
          },
          dark: {
            color: '#fff',
            backgroundColor: '#202020',
            opacity: '0.7',
          }
        }
      }
    }
  }
};
</script>

<style scoped>

</style>
