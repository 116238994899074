<template>
  <title>Registeren | Dividenduitkering</title>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12 d-md-none px-0 cpyl">
        <div class="du-lottie-player">
          <lottie-player v-pre autoplay loop mode="normal" src="drv.json" style="width: 100%;display: inline-block"></lottie-player>
        </div>
      </div>
      <div class="col-md-6 col-12 cpbm du-column min-height" :class="{'cptl cpxxl': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen}">

        <div class="du-title-text du-login-title d-flex align-items-center">
          <h1>Registreren</h1>
        </div>

        <div class="cmtm">
          <div class=" du-register-subtext cmbm">
            <p>Om een aanvraag te kunnen beginnen, dient u zich te registeren.</p>
          </div>
          <div class="">
            <div class="cmbm col-12">
              <!-- <p class="du-r-input-annotation">Voornaam*</p> -->
              <div class="du-question-input du-login-input mt-4">
                <input placeholder="Voornaam*" type="text" class="du-input du-r-input">
              </div>
            </div>
            <div class="cmbm col-12">
              <!-- <p class="du-r-input-annotation">Achternaam*</p> -->
              <div class="du-question-input du-login-input mt-4">
                <input placeholder="Achternaam*" type="text" class="du-input du-r-input">
              </div>
            </div>
            <div class="cmbm col-12">
              <!-- <p class="du-r-input-annotation du-login-input-annotation">Email*</p> -->
              <div class="du-question-input du-login-input mt-4">
                <input placeholder="E-mail*" type="text" class="du-input du-r-input">
              </div>
            </div>
            <div class="cmbm col-12">
              <div class="du-login-registerlink du-register-loginlink">
                <p class="">Nog geen account? <router-link tag="span" to="/login" class="ms-1">Inloggen</router-link></p>
              </div>
            </div>
              <div class="col-12" :class="{'mt-5': !windowCheckerData.isMobileScreen}">
                <router-link to="/verificatie/mail">
                  <button class="du-button du-button-dark disabled-button">
                    GA VERDER
                    <i class="fa-solid fa-chevron-right thick-icon"></i>
                  </button>
                </router-link>
              </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-md-flex d-none du-lottie-column d-flex align-items-center" :class="{'cptl justify-content-end pe-0': !windowCheckerData.isMobileScreen, 'p-0': windowCheckerData.isMobileScreen}">
        <div class="du-lottie-player h-100">
          <lottie-player v-pre loop autoplay mode="normal" src="drv.json" style="width: 100%;display: inline-block"></lottie-player>
        </div>
      </div>
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>
</template>

<script>
import WindowChecker from "@/components/WindowChecker.vue";

export default {
  name: "Register",
  components: {WindowChecker},
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
    }
  }
}
</script>

<style scoped>
/* body {
  background-color: #DFEAF8 !important;
} */
</style>
