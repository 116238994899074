import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.js"

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import {createApp, markRaw} from 'vue'
import { createPinia } from "pinia";
import { createAuth0 } from "@auth0/auth0-vue";
import authConfig from "../auth_config.json";

import App from './App.vue'
import {createRouter} from './router'

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app
    .use(pinia)
    .use(
        createAuth0({
            domain: authConfig.domain,
            clientId: authConfig.clientId,
            authorizationParams: {
                redirect_uri: window.location.origin,
            }
        })
    )
    .use(createRouter(app))
    .mount('#app')

// createApp(App).use(router).mount('#app')

