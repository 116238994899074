<template>
  <header>
    <div class="du-header">
      <div class="du-header-title">
        <router-link to="/">
          <!-- style="height: 9vh;"<h3 class="d-inline-block headerTitle" ><span>DIVIDEND</span>UITKERING.NL</h3> -->
          <img id="headerLogo" class="headerLogo" src="@/assets/Logo_Wit.png" alt="Dividend uitkering logo" srcset="">
        </router-link>
<!--        <p v-if="isMenuOpen">{{isMenuOpen}}</p>-->
      </div>
<!--      <p> Count is {{ store.count }}</p>-->
<!--      <p> Count is {{ store.test }}</p>-->
<!--      <button @click="store.increment(1)">Increment</button>-->
<!--      <button @click="store.waitAndAdd()">Wait and add</button>-->

      <button class="navbar-toggler ms-auto d-inline-block d-md-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              @click="isMenuOpen = !isMenuOpen">
        <span v-if="!isMenuOpen">
            <i class="fas fa-bars" ></i>
        </span>
        <span v-if="isMenuOpen">
            <i class="fas fa-xmark"></i>
        </span>
      </button>
    </div>
  </header>
</template>

<script>

export default{
  data(){
    return {
      menuStatus: "fas fa-bars",
      isMenuOpen: false
    }
  },
}
</script>
