<template>

</template>

<script>
export default {
  name: "windowChecker",


  data(){
    return {
      isMobileScreenXS: false,
      isMobileScreen: false,
      isTabletScreen: false,
      windowWidth: window.innerWidth
    }
  },

  mounted() {
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
      this.isMobileScreenXS = window.innerWidth < 576
      this.isMobileScreenS = window.innerWidth >= 576 && window.innerWidth < 768
      this.isMobileScreen = window.innerWidth < 768
      this.isTabletScreen = window.innerWidth >= 768 && window.innerWidth < 991
      this.$emit('resize-window', {
        isMobileScreenXS: this.isMobileScreenXS,
        isMobileScreenS: this.isMobileScreenS,
        isMobileScreen: this.isMobileScreen,
        isTabletScreen: this.isTabletScreen
      });

    }

  },
}
</script>

<style scoped>

</style>
