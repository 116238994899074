<template>
  <title>Hoe werkt het | Dividenduitkering</title>
  <div class="container-fluid" :class="{'cpbm': windowCheckerData.isMobileScreen}">
    <div class="row">
      <div class="col-12 d-md-none px-0 cpyl">
        <div class="du-lottie-player">
          <lottie-player v-pre autoplay loop mode="normal" src="drv.json" style="width: 100%;display: inline-block"></lottie-player>
        </div>
      </div>
      <div class="col-md-6 col-12" :class="{'cptl cpxxl': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen}">

        <!-- <div class="du-title-block d-inline-block mb-md-3">
          <div class="du-title-text h-100 d-flex align-items-center">
            <h1>HOE WERKT HET?</h1>
          </div>
        </div> -->

        <div class="cmtm">
          <div class="cmbm">
            <h2 class="largeText">Page</h2>
          </div>

          <div class="cmbm">
            <h3>Sub</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit <span>Lorem ipsum.</span></p>
          </div>

          <div class="cmbm">
            <h3>Sub 2</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>

        </div>

      </div>

      <div class="col-md-6 d-md-flex d-none du-lottie-column d-flex align-items-center" :class="{'cptl justify-content-end pe-0': !windowCheckerData.isMobileScreen, 'p-0': windowCheckerData.isMobileScreen}">
        <div class="du-lottie-player h-100">
          <!-- <lottie-player v-pre loop autoplay mode="normal" src="drv.json" style="width: 100%;display: inline-block"></lottie-player> -->
          <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">
        </div>
      </div>

    </div>
  </div>
<!--  <div class="container-fluid">-->
<!--    <div class="row">-->
<!--      <div class="col-md-6 col-12">-->

<!--        <div class="du-title-block d-inline-block mb-md-3">-->
<!--          <div class="du-title-text h-100 d-flex align-items-center">-->
<!--            <h1>HOE WERKT HET?</h1>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="du-content">-->
<!--          <div class="du-content-title">-->
<!--            <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</h2>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->

<!--      <div class="col-md-6 col-12 du-column du-lottie-column d-flex align-items-center pt-0 pb-0 pe-0">-->
<!--        <div class="du-lottie-player">-->
<!--          <lottie-player v-pre-->
<!--                         autoplay-->
<!--                         loop-->
<!--                         mode="normal"-->
<!--                         src="drv.json"-->
<!--                         style="width: 80%;display: inline-block"-->
<!--          >-->
<!--          </lottie-player>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->
<!--  </div>-->
  <WindowChecker @resize-window="windowChecker"></WindowChecker>

</template>

<script>

import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import WindowChecker from "@/components/WindowChecker.vue";



export default {
  name: `HowItWorks`,
  components: {WindowChecker,Navigation, Header, Footer},
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
    }
  }
};
</script>

<style scoped>

</style>
