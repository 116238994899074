<template>
  <title>Aanvraag starten | Dividenduitkering</title>
  <div class="container-fluid" :class="{'cpbm': windowCheckerData.isMobileScreen}">
    <div class="row">
      <div class="col-12 d-md-none px-0 cpyl">
        <div class="du-lottie-player">
          <lottie-player v-pre autoplay loop mode="normal" src="drv.json" style="width: 100%;display: inline-block"></lottie-player>
        </div>
      </div>
      <div class="col-md-6 col-12 " :class="{
        'min-height cpyl cpxxl': !windowCheckerData.isMobileScreen,
        'cpxm': windowCheckerData.isMobileScreen,
        'min-height-mobile-s': windowCheckerData.isMobileScreenS,
        'min-height-mobile-xs': windowCheckerData.isMobileScreenXS
      }">

        <!-- <div class="du-title-block d-inline-block mb-md-3">
          <div class="du-title-text h-100 d-flex align-items-center">
            <h1>VOORDAT U BEGINT</h1>
          </div>
        </div> -->

        <div class="cmtm">
          <div class="cmbm">
            <h2 class="largeText" >Doormiddel van een serie aan vragen achterhalen wij de mogelijkheid en omvang van uw recht tot het uitkeren van dividend uit uw onderneming.</h2>
          </div>

          <div class="cmbm">
            <h3>Neem de tijd</h3>
            <p>Gemiddeld kost het invullen u ruim <span>30 minuten</span>.</p>
          </div>

          <div class="cmbm">
            <h3>Belangrijk</h3>
            <p>Als gevolg van de huidige AVG-wetgeving is het helaas niet mogelijk om het invullen van de vragen tussentijds te staken en op een later tijdstip te hervatten.</p>
          </div>

          <div class="du-question-controls du-controls-content d-flex">
            <div class="w-100">

              <div class="du-question-control-left d-md-inline-block d-block cmbs">
                <router-link to="/overzicht" class="w-100 d-block">
                  <button class="du-button w-100">START AANVRAAG
                    <i class="fa-solid fa-chevron-right thick-icon"></i>
                  </button>
                </router-link>
              </div>

            </div>
          </div>

        </div>

      </div>

      <div class="col-md-6 d-md-flex d-none du-lottie-column" :class="{'cptl justify-content-end pe-0': !windowCheckerData.isMobileScreen, 'p-0': windowCheckerData.isMobileScreen}">
        <div class="du-lottie-player h-100">
          <!-- <lottie-player v-pre loop autoplay mode="normal" src="drv.json" style="width: 100%;display: inline-block"></lottie-player> -->
          <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">

        </div>
      </div>

    </div>
  </div>
<!--  <div class="container-fluid">-->
<!--    <div class="row">-->

<!--      <div class="col-md-6 du-column">-->

<!--        <div class="du-title-block d-inline-block mb-md-3">-->
<!--          <div class="du-title-text h-100 d-flex align-items-center">-->
<!--            <h1>VOORDAT U BEGINT</h1>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="du-content">-->
<!--          <div class="du-content-title">-->
<!--            <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</h2>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="du-content-subtitle">-->
<!--          <h3>Neem de tijd</h3>-->
<!--          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit <span>Lorem ipsum.</span></p>-->
<!--        </div>-->

<!--        <div class="du-content-subtitle du-last-content">-->
<!--          <h3>Lorem ipsum</h3>-->
<!--          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
<!--        </div>-->

<!--        <div class="du-question-controls du-controls-startrequest d-flex">-->
<!--          <div class="w-100">-->

<!--            <div class="du-question-control-left d-inline-block">-->
<!--              <router-link to="/vragenlijst">-->
<!--                <button class="du-button">START AANVRAAG-->
<!--                  <i class="fa-solid fa-chevron-right thick-icon"></i>-->
<!--                </button>-->
<!--              </router-link>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->

<!--      </div>-->

<!--      <div class="col-md-6 col-12 du-column du-lottie-column d-flex align-items-center pt-1 pb-0 pe-0">-->
<!--        <div class="du-lottie-player du-lottie-margin">-->
<!--          <lottie-player v-pre-->
<!--                         autoplay-->
<!--                         loop-->
<!--                         mode="normal"-->
<!--                         src="drv.json"-->
<!--                         style="width: 80%;display: inline-block"-->
<!--          >-->
<!--          </lottie-player>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <WindowChecker @resize-window="windowChecker"></WindowChecker>


</template>

<script>

import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import WindowChecker from "@/components/WindowChecker.vue";


export default {
  name: `StartRequest`,
  components: {WindowChecker, Navigation, Header, Footer},
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
    }
  }
};
</script>

<style scoped>

</style>
