import { defineStore } from "pinia";
import {useRoute, useRouter} from "vue-router";

export const useUserStore = defineStore("user", {
    state: () => ({
        user: null,
        isLoggedIn: false,
        redirectFail: false,
        falseLogin: 0
        // count: 0,
        // check: false,
    }),

    actions: {

        async signIn(email: string, password: string) {
            if (email.length > 0 && password.length > 0) {
                const API_URL = 'https://nodejs.imaginecreativeagency.nl/drv/users/index.php'
                const res = await fetch(API_URL, {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({"mail": email, "pw": password})
                });
                const user = await res.json();
                if (user['success'] === true && user['data'] !== false) {
                    this.isLoggedIn = true
                    this.user = user['data'];
                    return user;
                } else {
                    this.falseLogin++;
                    return user
                }
            } else {
                return false
            }
        },
    },
    persist: {
        storage: sessionStorage,
    },
});